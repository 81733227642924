import React from "react"
import ContactBlock from '@Components/Home/ContactBlock/ContactBlock'
import { useStaticQuery, graphql } from "gatsby";
const StaticContactBlock = ( props ) => {
    const data = useStaticQuery(graphql`
    query {
          strapiContactBlock {
            CTA_1_Label
            CTA_1_URL {
              Alias
            }
            CTA_2_Label
            CTA_2_URL {
              Alias
            }
            Content
            Embed_Video_URL
            Title
            Video_Ask
            Image {
              internal {
                description
              }
            }
          }
    }
`);
    return(<ContactBlock 
        Title={data.strapiContactBlock?.Title}
        Content={data.strapiContactBlock?.Content}
        CTA_1_Label={data.strapiContactBlock?.CTA_1_Label}
        CTA_2_Label={data.strapiContactBlock?.CTA_2_Label}
        CTA_1_URL={data.strapiContactBlock?.CTA_1_URL}
        CTA_2_URL={data.strapiContactBlock?.CTA_2_URL}
        Image={{url: data.strapiContactBlock?.Image?.internal?.description?.replace("File ","").replace('"','').replace('"','')}}
        Video={props.teamvideoaskurl ? props.teamvideoaskurl : data.strapiContactBlock?.Embed_Video_URL}
        Video_Ask ={data.strapiContactBlock?.Video_Ask}
    />)
}



export default StaticContactBlock